import React from 'react';
import { useContext } from 'react';
import { LangContext } from '../Layout';
import CircleOrange from '../../img/circle-orange.inline.svg';
import './style.scss';
import { ArrowForward } from '@material-ui/icons';
import { Link } from 'gatsby';

const OrangeCircle = ({ title, image }) => (
  <div className="icon-container">
    <CircleOrange className="icon-container__image" />
    {image && <img src={image} alt={title} className="icon-container__icon" />}
  </div>
);

const Track = ({ item }) => {
  const { language } = useContext(LangContext);

  return (
    <div className="track">
      {item.icon && (
        <OrangeCircle
          title={item['title_' + language]}
          image={item.icon.publicURL}
        />
      )}
      <div className="track__title">{item['title_' + language]}</div>
      <div className="track__desc">{item['desc_' + language]}</div>
    </div>
  );
};

const Tracks = ({ data }) => {
  const { language } = useContext(LangContext);
  return (
    <div className="tracks">
      <div className="tracks__title">{data['title_' + language]}</div>
      <div className="tracks__middle">
        {data.track.map((item, index) => (
          <Track item={item} key={index} />
        ))}
      </div>
      <div className="tracks__bottom">
        <div className="tracks__bottom__title">
          {data['subtitle_' + language]}
        </div>
        <div className="tracks__bottom__subtitle">
          {data['desc_' + language]}
        </div>
        <Link to="https://jobs.lever.co/theodo?department=Theodo%20Maroc">
          <button className="tracks__bottom__button">
            {data['button_' + language]}
            <ArrowForward fontSize="small" className="materiel-icon" />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Tracks;
