// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-apply-page-js": () => import("./../../../src/templates/apply-page.js" /* webpackChunkName: "component---src-templates-apply-page-js" */),
  "component---src-templates-career-page-js": () => import("./../../../src/templates/career-page.js" /* webpackChunkName: "component---src-templates-career-page-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-expertise-js": () => import("./../../../src/templates/expertise.js" /* webpackChunkName: "component---src-templates-expertise-js" */),
  "component---src-templates-footer-js": () => import("./../../../src/templates/footer.js" /* webpackChunkName: "component---src-templates-footer-js" */),
  "component---src-templates-header-js": () => import("./../../../src/templates/header.js" /* webpackChunkName: "component---src-templates-header-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-methodology-page-js": () => import("./../../../src/templates/methodology-page.js" /* webpackChunkName: "component---src-templates-methodology-page-js" */),
  "component---src-templates-navbar-js": () => import("./../../../src/templates/navbar.js" /* webpackChunkName: "component---src-templates-navbar-js" */)
}

